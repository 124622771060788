import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useStaticQuery, graphql, Link } from "gatsby"
import Slider from "react-slick"
import axios from "axios"
import Img from "gatsby-image"
import MuiAlert from "@material-ui/lab/Alert"

import {
  makeStyles,
  useTheme,
  Container,
  Button,
  Grid,
  Typography,
  Box,
  AppBar,
  Toolbar,
  useMediaQuery,
  Snackbar,
  ButtonGroup,
  CircularProgress,
  FormControl,
  TextField,
} from "@material-ui/core"

import SEO from "../components/seo"
import SingleAuthor from "../components/single-author"
import SingleSlide from "../components/single-slide"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import HeroBg from "../../static/images/hero-bg-min.png"
import HeroBg2 from "../../static/images/hero-bg-min.jpg"
import aboutSec4 from "../../static/images/aboutSec4.png"
import AboutBgImage from "../../static/images/aboutBg.svg"
import Logo from "../../static/images/logo/logo_signup.svg"
import HeroPhone from "../../static/images/hero-phone-2-min.png"
import aboutSec1 from "../../static/images/Icons/aboutSec1.png"
import aboutSec2 from "../../static/images/Icons/aboutSec2.png"
import aboutSec3 from "../../static/images/Icons/aboutSec3.png"
import NewsLatterImage from "../../static/images/NewsLatterBg.jpg"
import StoreIcons1 from "../../static/images/Icons/store-icon.svg"
import StoreIcons2 from "../../static/images/Icons/store-icon-2.svg"
import CategoryImg from "../../static/images/Icons/categoryImage.svg"
import FooterCategory1 from "../../static/images/Icons/CategoryIcons1.svg"
import FooterCategory2 from "../../static/images/Icons/CategoryIcons2.svg"
import FooterCategory3 from "../../static/images/Icons/CategoryIcons3.svg"

const IndexPage = ({ pageContext }) => {
  const classes = useStyles()
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const { homeSections, pLang } = pageContext

  const [loading] = useState(false)
  const [openSuccessSnackbar, setOpenSuccessSnackbar] = useState(false)
  // const [homeSections, setHomeSection] = useState([])

  useEffect(() => {
    i18n.on("languageChanged", () => {
      windowGlobal.history.pushState(pLang, `/${i18n.language}`)
      if (windowGlobal.history.pushState) {
        windowGlobal.history.pushState(pLang, `home`, `/${i18n.language}`)
        // windowGlobal.location.reload()
      } else {
        documentGlobal.location.href = `/${i18n.language}`
      }
    })
  }, [i18n, pLang])

  // Romved because of google analytics 
  // useEffect(() => {
  //   if (windowGlobal) {
  //     if (windowGlobal.location.pathname == "/") {
  //       windowGlobal.location.href = `/${i18n.language}`
  //     }
  //   }
  // }, [windowGlobal, i18n])

  const { allStrapiSliders, allStrapiCategories, allStrapiAuthors } = useStaticQuery(
    graphql`
    query {
      allStrapiSliders {
        nodes {
          id
          link
          image {
            publicURL
            childImageSharp {
              fixed(width: 1280, height: 600, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
                srcWebp
              }
            }
          }
          lang {
            code
          }
        }
      }
      allStrapiCategories {
        nodes {
          id
          title
          title_ar
          title_tr
          strapiId
          backgroundColor
          image {
            publicURL
            childImageSharp {
              fixed(height: 76) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
                srcWebp
              }
            }
          }
        }
      }
      allStrapiAuthors(filter: { partner: { eq: true } }) {
        nodes {
          firstName
          firstName_ar
          firstName_tr
          lastName
          lastName_ar
          lastName_tr
          strapiId
          description
          image {
            publicURL
            childImageSharp {
              fixed(width: 200, height: 200, quality: 100) {
                ...GatsbyImageSharpFixed_withWebp
                srcWebp
                tracedSVG
              }
              fluid {
                ...GatsbyImageSharpFluid_withWebp
                srcWebp
                tracedSVG
              }
            }
          }
        }
      }
    }
    `
  )

  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"))
  const isMobile = useMediaQuery("(max-width:600px)")

  const settings2 = {
    infinite: true,
    speed: 500,
    swipeToSlide: true,
  }

  const handleOpenSnackbar = () => {
    setOpenSuccessSnackbar(true)
    setTimeout(() => {
      setOpenSuccessSnackbar(false)
    }, 3000)
  }

  const handleCloseAddToCard = () => {
    setOpenSuccessSnackbar(false)
  }

  const authors = []
  for (let author of allStrapiAuthors.nodes) {
    let objCopy = Object.assign({}, author)
    authors.push(objCopy)
  }

  return (
    <>
      <SEO title={t("home.title")} lang={i18n.language} />
      <Container className={`${classes.fullWidth} ${classes.heroBg}`}>
        <Container>
          <Grid container directoin="row" justify="center" alignItems="center">
            <Grid item md={6} xs={12} className={`${classes.textCenter} ${classes.heroOnMobile}`}>
              <img src={HeroPhone} alt="hero phone" className={classes.heroPhone} width="304" height="606" />
            </Grid>
            <Grid item md={6} xs={12} className={`${classes.textCenter} ${classes.heroSecondSec}`}>
              <img src={Logo} alt="hero logo" className={classes.heroLogo} />
              <div className={classes.heroText}>
                <Typography variant="h6">{t("home.heroText1")}</Typography>
                <Typography variant="h6">{t("home.heroText2")}</Typography>
              </div>
              <Box display="flex" justifyContent="center">
                <Box className={classes.heroStoreIcons}>
                  <a href="https://play.google.com/store/apps/details?id=com.arafas.kitabhadef">
                    <img src={StoreIcons1} alt="Google Play" width="160" height="58" />
                  </a>
                </Box>
                <Box className={classes.heroStoreIcons}>
                  <a href="https://apps.apple.com/us/app/كتابي-الهادف/id1565685455">
                    <img src={StoreIcons2} alt="App Store" width="160" height="58" />
                  </a>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center">
                <Box p={1} m={1} className={classes.heroBoxes}>
                  <img src={FooterCategory1} alt="Foliar" />
                  <Typography variant="subtitle1">{t("footer.foliar")}</Typography>
                </Box>
                <Box p={1} m={1} className={classes.heroBoxes}>
                  <img src={FooterCategory2} alt="Electronic" />
                  <Typography variant="subtitle1">{t("footer.electronic")}</Typography>
                </Box>
                <Box p={1} m={1} className={classes.heroBoxes}>
                  <img src={FooterCategory3} alt="Vocal" />
                  <Typography variant="subtitle1">{t("footer.vocal")}</Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Container className={`${classes.fullWidth} ${classes.gradientBg}`}>
        <AppBar position="static" className={classes.recentlyAppBar}>
          <Toolbar className={classes.recentlyReleased}>
            <Typography variant="h6" component="h2" className={classes.recentlyTitle}>
              {t("home.newRelased")}
            </Typography>
          </Toolbar>
        </AppBar>
        <Container style={{ padding: 0 }}>
          <Slider
            infinite={true}
            speed={500}
            swipeToSlide={true}
            slidesToShow={1}
            dots={true}
            className={`${classes.directionLtr}  home-main-slider`}
          >
            {/* TODO: fix Sliders link to be gatsby link */}
            {allStrapiSliders.nodes
              .filter(item => item.lang.code === i18n.language)
              .map((step, index) => (
                <div key={step.id}>
                  <a href={step.link}>
                    {/* <img src={step.image.publicURL} style={{width: "1280px", height: "600px"}} className={classes.sliderImage} alt="step" /> */}
                    <Img fixed={step.image.childImageSharp.fixed} className={classes.sliderImage} alt="step" />
                    {/* <img
                    className={classes.sliderImage}
                    src={step.image && step.image.childImageSharp.fixed}
                    src={step.image.publicURL}
                    alt="step"
                  /> */}
                  </a>
                </div>
              ))}
          </Slider>
        </Container>
      </Container>
      <Container className={`${classes.fullWidth} ${classes.bookSliderContainer}`}>
        {homeSections.data.map(item => (
          <Container
            key={item.sectionId}
            className={`${classes.fullWidth} ${classes.bookSliderContainerInner} bookSliderContainerInner`}
          >
            <Container>
              <Typography variant="h5" component="h2" className={classes.secTitle}>
                {item.sectionTitle}
              </Typography>
              <Slider
                infinite={true}
                speed={500}
                swipeToSlide={true}
                slidesToShow={isMobile ? 1 : isTablet ? 2 : item.books.length > 3 ? 4 : item.books.length}
                className={`${classes.directionLtr} ${classes.sliderStyle}`}
              >
                {item.books.map(book => (
                  <SingleSlide key={book.id} slideBooks={book} openSnackbar={handleOpenSnackbar} ishome={true} />
                ))}
              </Slider>
            </Container>
          </Container>
        ))}
      </Container>

      <Container className={`${classes.fullWidth} ${classes.categoryBg}`}>
        <Container>
          <Typography variant="h4" component="h2" className={`${classes.secTitle} ${classes.secTitleCategories}`}>
            {t("home.mainCategoriesTitle")}
          </Typography>
          <Grid container spacing={4} className={classes.categoryGrid}>
            {/* TODO: Fix categories images to be static */}
            {allStrapiCategories.nodes.map((category, key) => (
              <Grid item xs={6} sm={6} lg={3} md={3} key={category.id} className={classes.categoryGridItem}>
                <Link to={`/${i18n.language}/categories/${category.strapiId}`} className={classes.categoryLink}>
                  <Box
                    p={1}
                    bgcolor={
                      category.backgroundColor && category.backgroundColor.startsWith("#")
                        ? category.backgroundColor
                        : category.backgroundColor !== null
                        ? `#${category.backgroundColor}`
                        : "rgb(0, 62, 150)"
                    }
                    className={classes.categoryBoxes}
                  >
                    {category.image ? (
                      // <>
                      // <img src={category.image.publicURL} style={{height: "76px"}} className={classes.categoryIcon}/>
                      <Img fixed={category.image.childImageSharp.fixed} className={classes.categoryIcon} />
                    ) : (
                      // </>
                      <img p={3} src={CategoryImg} alt={category.title} className={classes.categoryIcon} />
                    )}
                    <Typography variant="h5" component="p">
                      {i18n.language === "en" && category.title}
                      {i18n.language === "ar" && category.title_ar}
                      {i18n.language === "tr" && category.title_tr}
                    </Typography>
                  </Box>
                </Link>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Container>
      <Container className={`${classes.fullWidth} ${classes.aboutBg}`}>
        <Container className={classes.aboutInnerSec1}>
          <Typography variant="h5" component="h2" className={classes.secTitle}>
            {t("home.aboutUs")}
          </Typography>
          <Grid container className={classes.aboutGrid}>
            <Grid item xs={12} sm={4} md={4} className={classes.aboutInnerCol1}>
              <img src={aboutSec1} alt="distributor" width="65" height="66" />
              <Typography variant="subtitle1" component="p" className={classes.aboutInnerNumber}>
                30
              </Typography>
              <Typography variant="subtitle1" component="p" className={classes.aboutInnerText}>
                {t("home.distributors")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.aboutInnerCol2}>
              <img src={aboutSec2} alt="customers" width="64" height="60" />
              <Typography variant="subtitle1" component="p" className={classes.aboutInnerNumber}>
                255
              </Typography>
              <Typography variant="subtitle1" component="p" className={classes.aboutInnerText}>
                {t("home.versionsNumber")}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} className={classes.aboutInnerCol3}>
              <img src={aboutSec3} alt="customers" width="84" height="68" />
              <Typography variant="subtitle1" component="p" className={classes.aboutInnerNumber}>
                1999
              </Typography>
              <Typography variant="subtitle1" component="p" className={classes.aboutInnerText}>
                {t("home.foundationYear")}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={`${classes.fullWidth} ${classes.aboutBgInner} aboutBg`}>
          <Grid container className={classes.aboutInnerSec2}>
            <Grid item xs={false} sm={false} md={4} className={classes.aboutSecPhone}>
              <img src={aboutSec4} alt="about" />
            </Grid>
            <Grid item></Grid>
          </Grid>
        </Container>
      </Container>
      <Container className={`${classes.fullWidth} ${classes.newsLatterBg}`}>
        <Container>
          <Grid container className={classes.newsLatterGrid}>
            <Grid item sm={false} md={3}></Grid>
            <Grid item sm={12} md={3}>
              <Typography variant="h5" component="h3">
                {t("home.mailingList")}
              </Typography>
              <Typography variant="subtitle1" component="p">
                {" "}
                {t("home.mailingDescription")}{" "}
              </Typography>
            </Grid>
            <Grid item sm={12} md={3}>
              <form>
                <FormControl fullWidth className={`${classes.margin} ${classes.formConrolNewsLatter}`}>
                  <TextField
                    required
                    id="email"
                    type="email"
                    label={t("home.email")}
                    variant="outlined"
                    className={classes.newsLatterField}
                  />
                </FormControl>
                <ButtonGroup
                  color="primary"
                  aria-label="Create account"
                  disabled={loading}
                  loading={loading.loading}
                  className={`${classes.button} ${classes.buttonSignupNewsLatter} button`}
                >
                  <Button type="submit" variant="contained" className={classes.createAccountBtn}>
                    {loading && <CircularProgress size={26} />}
                    {!loading && t("contactUs.submitBtn")}
                  </Button>
                </ButtonGroup>
              </form>
            </Grid>
            <Grid item sm={false} md={3}></Grid>
          </Grid>
        </Container>
      </Container>
      <Container className={`${classes.fullWidth} ${classes.authorsSliderContainer}`}>
        <Container className={classes.bookSliderContainerInner}>
          <Typography variant="h5" component="h2" className={classes.secTitle}>
            {t("home.ourPartners")}
          </Typography>
          <Slider
            {...settings2}
            infinite={true}
            speed={500}
            swipeToSlide={true}
            slidesToShow={isMobile ? 1 : isTablet ? 2 : authors.length > 3 ? 4 : authors.length}
            className={`${classes.directionLtr}`}
          >
            {authors.map(item => (
              <div className={classes.partnerSlide} key={item.strapiId}>
                <SingleAuthor authors={item} />
              </div>
            ))}
          </Slider>
        </Container>
      </Container>
      <Snackbar open={openSuccessSnackbar}>
        <Alert onClose={handleCloseAddToCard} severity="success">
          {t("book.itemAddedToCart")}
        </Alert>
      </Snackbar>
    </>
  )
}
export default IndexPage

const useStyles = makeStyles(theme => ({
  sliderImage: {
    width: "100% !important",
    height: "auto !important",
    maxHeight: "600px !important",
    margin: "0 auto !important",
    display: "block !important",
    overflow: "hidden !important",
    "& picture img": {
      position: "initial !important",
      top: "initial !important",
      left: "initial !important",
      objectFit: "initial !important",
      objectPosition: "initial !important",
      opacity: "1 !important",
    },
  },
  fullWidth: {
    paddingLeft: "initial",
    paddingRight: "initial",
    position: "relative",
    maxWidth: "100%",
  },
  sliderParent: {
    height: 600,
    margin: "0 auto",
  },
  gradientBg: {
    backgroundImage: "linear-gradient(to bottom, #f2f2f2 6%, #dddddd 99%)",
  },
  sliderBtns: {
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
  },
  sliderBtnPrev: {
    left: 0,
  },
  sliderBtnNext: {
    right: 0,
  },
  dotsStepper: {
    background: "transparent",
    position: "absolute",
    left: "50%",
    transform: "translateX(-50%)",
    bottom: 10,
  },
  heroBg: {
    // backgroundImage: `url(${HeroBg})`,
    backgroundImage: `url(${HeroBg2})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "84% center",
    zIndex: -1,
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center",
    },
    [theme.breakpoints.up("md")]: {
      backgroundSize: "contain",
    },
  },
  heroOnMobile: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  textCenter: {
    textAlign: "center",
  },
  heroStoreIcons: {
    width: "10rem",
    margin: "2rem 0.4rem",
    // zIndex: 999
  },
  heroBoxes: {
    margin: theme.spacing(2),
    marginTop: "initial",
    "& img": {
      height: "4.5rem",
      width: "3.5rem",
      margin: "0 auto 5px",
    },
  },
  heroLogo: {
    width: "9.6rem",
    height: "auto",
  },
  heroSecondSec: {
    margin: "3.5rem 0",
  },
  heroText: {
    margin: "2rem 0.4rem 0",
    color: "#003e96",
    "& h6": {
      lineHeight: "34px",
      fontWeight: "900",
    },
  },
  heroPhone: {
    width: "19rem",
    margin: "1rem 0",
  },
  recentlyTitle: {
    margin: "0 auto",
  },
  recentlyAppBar: {
    backgroundColor: "#003e96",
    boxShadow: "0 0 0",
  },
  directionLtr: {
    flip: false,
  },
  secTitle: {
    marginBottom: ".5rem",
    textAlign: "center",
    color: "#003e96",
  },
  sliderStyle: {
    marginBottom: "1rem",
    minHeight: 400,
  },
  authorsSliderContainer: {
    backgroundColor: "#f5f5f5",
  },
  bookSliderContainerInner: {
    paddingTop: "3rem",
    paddingBottom: ".8rem",
  },
  categoryBoxes: {
    textAlign: "center",
    color: "white",
    paddingTop: 20,
    paddingBottom: 20,
    border: "solid 1px #707070",
    borderRadius: 10,
    "& p": {
      textShadow: "0 10px 6px rgba(0, 0, 0, 0.16)",
      fontSize: "1.3rem",
    },
  },
  categoryBg: {
    // backgroundImage: `url(${CategoryBg})`,
    backgroundColor: "#fff",
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(5),
  },
  secTitleCategories: {
    marginBottom: theme.spacing(4),
  },
  aboutBg: {
    backgroundImage: `linear-gradient(to bottom, #ffffff, #d6d2d2)`,
  },
  aboutBgInner: {
    backgroundImage: `url(${AboutBgImage})`,
    backgroundRepeat: "no-repeat",
    display: "block",
    backgroundSize: "contain",
    overflow: "hidden",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      backgroundSize: "cover",
    },
  },
  aboutSecPhone: {
    "& img": {
      maxWidth: "90%",
      position: "absolute",
      bottom: "-7rem",
      width: "30rem",
      left: "2rem",
    },
  },
  aboutInnerSec2: {
    height: "20rem",
  },
  aboutInnerSec1: {
    textAlign: "center",
    paddingTop: "3rem",
  },
  aboutInnerCol1: {
    color: "#32a4ff",
    textShadow: "0 10px 6px rgba(0, 0, 0, 0.16)",
  },
  aboutInnerCol2: {
    color: "#32a4ff",
    textShadow: "0 10px 6px rgba(0, 0, 0, 0.16)",
  },
  aboutInnerCol3: {
    color: "#32a4ff",
    textShadow: "0 10px 6px rgba(0, 0, 0, 0.16)",
  },
  aboutInnerNumber: {
    fontSize: "2rem",
  },
  aboutInnerText: {
    fontSize: "1.5rem",
  },
  newsLatterBg: {
    backgroundImage: `url(${NewsLatterImage})`,
    backgroundRepeat: "no-repeat",
    display: "block",
    backgroundSize: "cover",
    "&::before": {
      content: '""',
      backgroundColor: "#018fff",
      position: "absolute",
      top: 0,
      right: 0,
      width: "100%",
      height: "100%",
      opacity: ".4",
    },
  },
  newsLatterGrid: {
    paddingTop: "3.5rem",
    paddingBottom: "2.7rem",
    position: "relative",
    color: "#fff",
  },
  formConrolNewsLatter: {
    color: "#fff",
    marginBottom: theme.spacing(1),
  },
  newsLatterField: {
    "& input, :not(.Mui-focused) span, label": {
      color: "#fff",
    },
    "& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff",
    },
  },
  buttonSignupNewsLatter: {
    float: "right",
    "& .MuiButton-containedPrimary": {
      backgroundColor: "transparent",
      boxShadow: "0 0 0 0",
      border: 0,
      borderRadius: 0,
    },
  },
  partnerSlide: {
    margin: "0 auto",
    textAlign: "center",
    outlineWidth: 0,
    "&:focus": {
      outlineWidth: 0,
    },
    "& *": {
      outlineWidth: 0,
      maxWidth: 260,
      textAlign: "center",
      margin: "0rem auto",
      // 2rem
      textDecoration: "none",
      color: "#003e96",
    },
    "& img": {
      textAlign: "center",
      margin: "0rem auto 0",
      // 2rem
    },
  },
  categoryLink: {
    textDecoration: "none",
  },
  aboutGrid: {
    marginTop: "1.5rem",
  },
  categoryIcon: {
    height: 76,
    width: "auto",
  },
  bookSliderContainer: {
    "& .bookSliderContainerInner:nth-child(even)": {
      backgroundColor: "#f5f5f5",
    },
  },
  "@global": {
    body: {
      position: "relative",
      zIndex: 1,
      "& main": {
        position: "relative",
        zIndex: 1,
      },
    },
  },
}))

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const windowGlobal = typeof window !== "undefined" && window
const documentGlobal = typeof document !== `undefined` && document
